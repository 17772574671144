#display_without_header {
    width: calc(100vw - 100px);
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 100px;
    padding: 20px;
    background: #eef2f5;
    border-radius: 40px 0 0 40px;
}

.contat {
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.today_attendance {
    background: #f5f5f5;
}

.today_attendance .cont_today_at {
    width: 100%;
    height: 100%;
}

#persona {
    width: 220px;
    border: 1px solid #cecece;
    position: relative;
    margin: 10px;
    float: left;
    cursor: default;
    height: 195px;
    border-radius: 15px;
    overflow: hidden;
}

#persona h3,
#persona h6,
#persona h1 {
    color: white;
    margin: 0;
}

#persona h6 {
    margin: 5px 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
}

#persona h1.hora {
    color: black;
    font-size: 25px;
    padding: 0 8px;
}

#persona .img-container {
    width: 100%;
    height: 165px;
    overflow: hidden;
    background: black;
    position: relative;
    border-radius: 15px;
}

#persona img {
    width: 100%;
}

#persona .datos {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: white !important;
    padding: 30px 0 10px 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

#persona .datos h3 {
    width: 100%;
    padding: 0 8px;
    z-index: 100;
    font-size: 14px;
    color: white !important;
    text-transform: uppercase;
    letter-spacing: 2px;
}

#persona .datos h1 {
    color: white !important;
    letter-spacing: 1px;
}

#persona .datos .almacen {
    padding: 5px 8px;
}

#persona .datos h6 {
    display: inline-block;
    height: 30px;
    padding: 5px 15px;
    border-radius: 15px;
    z-index: 100;
    font-size: 15px;
    margin: 10px 8px;
    color: white;
}

#persona.entrada {
    background: #259f62;
}

#persona.salida {
    background: #e10119;
}

#persona.entradac {
    background: #084353;
}

#persona.salidac {
    background: #fad260;
}

.nuevaAsistencia {
    padding: 20px 40px;
    background: white;
    background: rgba(250, 250, 250, 0.7);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    max-height: 530px;
}

.nuevaAsistencia.ventas {
    width: 400px;
    height: auto !important;
    padding: 20px 40px 50px;
    background: white;
    background: rgba(250, 250, 250, 0.7);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
    z-index: 999;
    position: fixed !important;
    top: 150px;
    left: 50%;
    margin-left: -200px;
}

.nuevaAsistencia h2 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}

.nuevaAsistencia h2 span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.nuevaAsistencia img {
    display: block;
    width: 85%;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.nuevaAsistencia .conSelect b {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.ant-picker {
    border-radius: 8px !important;
}

.sin-margen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}