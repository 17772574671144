:root {
    --container-background-color: #ffffff;
    --right-background-color: #c93623;
    --form-button-color: #4746BD;
    --headers-text: 'Muli';
    --comon-text: 'Roboto';
}

#login {
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    background: #F0F3F8;
}

.left2 {
    float: left;
    width: 45%;
    height: 100%;
    padding: 30px 40px;
    text-align: center;
}

.right2 {
    float: left;
    width: 55%;
    height: 100%;
    border-radius: 40px;
    background: url('https://cristophersosa.com/ionica/img/city_ion.png');
    text-align: center;
    color: white;
    padding: 0px 10px;
}

.right-cont {
    margin: 25% 10%;
}

.right-cont h3 {
    color: white;
}

input, select {
    height: 50px !important;
    border: 1px solid #bbbbbb;
    border-left: none;
    color: #BBBBC1;
}

.input-group>.input-group-prepend>.input-group-text {
    background: white!important;
    border-right: none;
}

.input-group>.input-group-prepend>.input-group-text>i {
    color: #bbbbbb;
}

input:focus {
    outline: 0px;
}

.btn {
    color: white;
    background: var(--form-button-color);
    padding: 12px 20px;
    width: 100%;
}

.ionred {
    width: 80%;
    height: auto;
    margin: 0px auto 20px auto;
}

.ionwhite {
    width: 60%;
    height: 10%;
}

.ionblack {
    right: 12%;
    width: 150px;
    height: 10%;
}

.disclaimer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 300px;
}


/* Web */

@media screen and (min-width:1024px) {
    .cont2 {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -220px;
        margin-left: -350px;
        width: 700px;
        height: 440px;
        border-radius: 40px;
        background: var(--container-background-color);
        -webkit-box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
        -moz-box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
        box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
    }
}


/* Tablet Horizontal */

@media screen and (max-width:1024px) {
    .cont2 {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -350px;
        margin-top: -210px;
        width: 700px;
        height: 420px;
        border-radius: 15px;
        background: var(--container-background-color);
        -webkit-box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
        -moz-box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
        box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
    }
    .left2 {
        float: left;
        width: 50%;
        height: 100%;
        padding: 5% 50px;
        text-align: center;
    }
    .right-cont {
        margin-top: 30%;
    }
}


/* Tablet Vertical */

@media screen and (max-width:768px) {
    .cont2 {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -250px;
        margin-top: -200px;
        width: 500px;
        height: 400px;
        border-radius: 15px;
        background: var(--container-background-color);
        -webkit-box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
        -moz-box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
        box-shadow: 1px 5px 50px -11px rgba(0, 0, 0, 0.62);
    }
    .left2 {
        float: left;
        width: 100%;
        height: 100%;
        padding: 5% 100px;
        text-align: center;
    }
    .right2 {
        display: none;
    }
}


/* Moviles */

@media screen and (max-width:450px) {
    .cont2 {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-top: 0;
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        background: var(--container-background-color);
        box-shadow: none;
    }
    .left2 {
        float: left;
        width: 100%;
        height: 100%;
        padding: 10% 30px;
        text-align: center;
    }
}