body{
    background: #f5f5f5!important;
}
#contenido{
    background: #f5f5f5;
}
header{
    background: black;
    position: fixed;
    top: 10px;
    left: 50px;
    color: white;
    width: calc(100% - 80px);
    height: 60px;
    padding: 10px 15px;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(214,214,214,1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(214,214,214,1);
    box-shadow: 0px 5px 10px 0px rgba(214,214,214,1);
    background: rgba(114, 114, 114, 0.7);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.15);
    z-index: 999;
}
header .logo{
    padding: 8px;
}
header .name span{
    display: block;
    font-weight: 700;
    font-size: 12px;
    width: 100%;
}
header .name p{
    font-weight: 700;
    font-size: 20px;
    position: relative;
    bottom: 7px;
}
header .hora p{
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    position: relative;
    bottom: 7px;
}
header .hora{
    text-align: center;
}
header .hora div{
    display: inline-block;
    border-radius: 8px;
    border: none;
    /* background: rgba(0,0,0,0.3); */
    height: 45px;
    padding: 0 10px;
}
header .hora span{
    text-align: center;
    display: block;
    font-weight: 700;
    font-size: 12px;
    width: 100%;
}
header .btn_container{
    text-align: right;
}
header .btn_container button{
    height: 40px;
    border-radius: 8px;
    border: none;
    background: rgba(0,0,0,0.2);
    margin: 0 5px;
    padding: 0 10px;
    transition-duration: 300ms;
}
header .btn_container button b{
    font-weight: 600;
    float: left;
    margin-left: 5px;
}
header .btn_container button i{
    padding-top: 3px;
    float: left;
    position: relative;
    bottom: 3px;
}
header .btn_container button.active{
    color: white;
    background: rgba(0,0,0,0.7);
}
header .btn_container .logout{
    float: right;
    margin-left: 18px;
    border-left: 1px solid #e2e2e2;
    padding-left: 13px;
}
header .btn_container button:hover{
    background: rgba(0,0,0,0.3);
}
header .btn_container button.active:hover{
    background: rgba(0,0,0,0.7);
}
#contenido > div.search_panel{
    margin-top: 100px;
    position: relative;
    background: #f5f5f5;
}
.head_search input, .head_search .input-group-text{
    border-radius: 50px;
}
.head_search .input-group{
    z-index: 100;
    position: absolute;
    top: 0;
    width: 60%;
    border-radius: 50px;
    margin: 20px 20%;
    -webkit-box-shadow: -1px 6px 20px 0px rgba(184,184,184,1);
-moz-box-shadow: -1px 6px 20px 0px rgba(184,184,184,1);
box-shadow: -1px 6px 20px 0px rgba(184,184,184,1);
}
.container_products{
    padding: 100px 5% 40px;
}

#producto {
    width: calc(50% - 20px);
    border: 1px solid #cecece;
    position: relative;
    margin: 10px;
    float: left;
    cursor: default;
    height: 220px;
    border-radius: 15px;
    overflow: hidden;
    background: #f5f5f5;
}


#producto .img-container{
    width: 150px;
    height: 220px;
    overflow: hidden;
    background: white;
    position: relative;
    border-radius: 15px;
    text-align: center;
    padding: 5px;
    float: left;
}
#producto .img-container img {
    max-width: 100%;
    max-height: 100%;
}

#producto .datos {
    width: calc(100% - 150px);
    float: left;
    text-align: center;
    color: white!important;
    padding: 10px;
}


#producto .datos h3 {
    width: 100%;
    padding: 0 8px;
    z-index: 100;
    font-size: 14px;
    text-transform:uppercase;
    letter-spacing: 1px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
#producto .datos h1 {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
}
#producto .datos p {
    font-size: 12px;
    color: black;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
#producto .precio{
    position: absolute;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    background: #2E9F62;
    bottom: 10px;
    right: 10px;
    color: white;
    overflow: hidden;
}
#producto .precio span{
    width: 60%;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    padding: 2px 0;
}
#producto .precio a{
    display: inline-block;
    color: white;
    width: 40%;
    height: 30px;
    text-align: center;
    border: none;
    background: none;
    font-size: 8px!important;
    padding: 2px;
    border-left: 1px solid white;
}
#producto .precio.no-link{
    width: 80px;
    background: #E10119;
}
#producto .precio.no-link span{
    width: 100%;
    
}
.container_products{
    height: 100%;
}
#conte{
    padding: 15px 40px 30px;
}
.item-manuales {
    background-color: #dcdcdc;
    border-radius: 12px;
    padding: 12px 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item-manuales .nombre {
    font-size: 16px;
}
.item-manuales .boton-cta {
    cursor: pointer;
    background-color: rgba(0,0,0,0.7);
    color: #fff;
    padding: 6px 48px;
    font-weight: 700;
    border-radius: 8px;
}