.modal-solicitud-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-solicitud-box {
    padding: 36px;
    z-index: 1001;
    width: 80%;
    max-height: 90vh;
    background-color: #f3f3f4;
    overflow-y: scroll;
}

.items-container .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    padding: 18px 48px;
    margin-bottom: 12px;

    .up, .down {
        cursor: pointer;
    }
}

.items-container .cerrar {
    font-family: inherit;
    font-weight: bolder;
    font-size: 14px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row-reverse;
}

.items-container .cerrar span {
    background-color: rgb(192, 127, 116);
    color: white;
    padding: 6px 12px;
}

.items-container .item .box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 24px;
}

.items-container .item .imagen img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.items-container .item .imagen {
 width: 40px;
 width: 40px;
}

.modal-solicitud-box input {
    border: solid 1px #cdcdcd;
    padding: 6px 12px;
    font-family: inherit;
}

.modal-solicitud-box .agregar button {
    border: none;
    background-color: #d9d9e0;
    padding: 6px 48px;
    font-family: inherit;
}

.modal-solicitud-box .enviar-solicitud {
    border: none;
    background-color: #d9d9e0;
    padding: 6px 48px;
    font-family: inherit;
    margin-top: 24px;
    font-weight: bold;
}